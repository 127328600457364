import React, { useEffect } from "react"
import authStore from "../stores/auth"
import loadingSpinner from "../imgs/loading-spinner.svg"

export default function CallbackPage() {
  useEffect(() => {
    authStore.handleAuthentication()
  })

  return (
    <div style={{ textAlign: "center" }}>
      <img src={loadingSpinner} alt="spinner" />
    </div>
  )
}
